import styled from 'styled-components';
import {media} from 'global.config';

export default styled.h1`
  font-size: 55px;
  font-weight: 400;
  line-height: 60px;
  margin: 0 0 20px;
  @media ${media.tablet} {
    font-size: 40px;
    line-height: 44px;
    margin: 0 0 10px;
  }
  @media ${media.mobile} {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 10px;
  }
`;
